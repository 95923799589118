import * as React from 'react'

const DashApp: React.FC = () => {
  return (
    <div className="App">
      <h1>Welcome to PRMetrics!</h1>
    </div>
  )
}

export default DashApp
